@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/
.main {
	overflow: hidden;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
$top_sp_hight: floor-decimal(calc(530 / 375) * 100) * 1vw; //スマホの高さ
.main-view {
	position: relative;
	@include media-breakpoint-up(md) {
		height: calc(6em + 46vw);
	}
	@include media-breakpoint-up(xl) {
		height: calc(6em + 740px);
	}
	@include media-breakpoint-up(full) {
		height: calc(6em + 38.5vw);
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
}

.main-view__bg {
	position: relative;
	overflow: hidden;
	top: 5em;
	@include media-breakpoint-up(md) {
		top: 6em;
		height: 46vw;
	}
	@include media-breakpoint-up(xl) {
		height: 740px;
	}
	@include media-breakpoint-up(full) {
		height: 38.5vw;
	}
	@include media-breakpoint-down(sm) {
		height: calc(#{$top_sp_hight} - 5em);
	}
	&__inner {
		height: 100%;
	}
	&__img {
		position: absolute;
		right: 0;
		width: 95%;
		height: 100%;
		z-index: z-index(module, part01);
		border-top-left-radius: clamp(12px, 5.65vw, 90px);
		border-bottom-left-radius: clamp(12px, 5.65vw, 90px);
		overflow: hidden;
		&__inner {
			height: 100%;
		}
	}
}

.main-view__inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: z-index(module, part02);
	&__txt {
		position: relative;
		height: 100%;
		width: 95%;
		left: 5%;
		@include media-breakpoint-up(md) {
			overflow: hidden;
		}
		&__inner {
			height: 100%;
		}
	}
	& .l-cont {
		height: 100%;
	}
}

.main-view__catch {
	position: absolute;
	top: 26%;
	left: -1px;
	z-index: z-index(module, part01);
	color: $black;
	width: 90%;
	@include media-breakpoint-up(md) {
		top: 22%;
		width: 60%;
	}
	@include media-breakpoint-up(xl) {
		top: 18%;
		width: 49.25%;
	}
}

.main-view__representative {
	position: absolute;
	bottom: 3.5%;
	max-width: 240px;
	right: -18%;
	width: 62vw;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(xs) {
		max-width: none;
		width: 62vw;
		right: -16%;
	}
	@include media-breakpoint-up(md) {
		right: 6%;
		bottom: 0;
		width: 32vw;
	}
	@include media-breakpoint-up(lg) {
		right: 6%;
		width: 32vw;
	}
	@include media-breakpoint-up(xl) {
		right: 14%;
		width: 530px;
	}
	@include media-breakpoint-up(full) {
		width: 15.1vw;
	}
	img {
		filter: drop-shadow(0px 0px 8px $white);
	}
}

.main-view__name {
	position: absolute;
	z-index: z-index(module, part02);
	background: $white;
	border-top-left-radius: clamp(6px, 2vw, 26px);
	border-bottom-right-radius: clamp(6px, 2vw, 26px);
	@include media-breakpoint-up(md) {
		bottom: 8%;
		right: 2%;
		max-width: none;
		width: 18vw;
	}
	@include media-breakpoint-up(lg) {
		width: 20vw;
	}
	@include media-breakpoint-up(xl) {
		width: 290px;
	}
	@include media-breakpoint-up(full) {
		width: 15.1vw;
	}
	@include media-breakpoint-down(sm) {
		width: 46vw;
		bottom: -2.5em;
		right: 0;
		padding-left: 2%;
		padding-right: 8%;
	}
}

.main-view__btn {
	position: absolute;
	left: 0;
	bottom: 9%;
	z-index: z-index(module, part04);
	width: 90%;
	@include media-breakpoint-up(sm) {
		bottom: 11%;
		width: 85%;
	}
	@include media-breakpoint-up(md) {
		left: 2%;
		bottom: 7%;
		width: 58%;
	}
	@include media-breakpoint-up(xl) {
		bottom: 5%;
		left: 5%;
		width: 44%;
	}
	@include media-breakpoint-up(full) {
		width: 79.16%;
		max-width: none;
	}
	&__inner {
		position: relative;
		width: 100%;
		aspect-ratio: 5 / 2.1;
	}
	&__bg {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: -1;
	}
	&__list {
		display: flex;
		width: 82%;
		column-gap: 3.71%;
		@include m-a;
		position: relative;
		z-index: z-index(module, part03);
		& > * {
			width: 30.86%;
		}
	}
	.btn-wrap {
		a {
			img {
				@include transition;
			}
		}
	}
	.main-view__btn__01 a:hover img {
		filter: drop-shadow(0px 0px 8px $sub_c);
	}
	.main-view__btn__02 a:hover img {
		filter: drop-shadow(0px 0px 8px $other_c1);
	}
	.main-view__btn__03 a:hover img {
		filter: drop-shadow(0px 0px 8px $main_c);
	}
}

/* scroll */
.scroll-line {
	position: absolute;
	//left: unquote("min(2%,4rem)");
	left: min(1.5%, 3rem);
	bottom: 0;
	writing-mode: vertical-rl;
	z-index: z-index(module, part04);
	@include media-breakpoint-down(sm) {
		display: none;
	}
	a {
		@include dec-none;
		@include f-family(font02);
		@include f-w(400);
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: $other_c2;
		@include transition;
		&:hover {
			color: $hover_c;
			span::before {
				background: $hover_c;
			}
		}
	}
	&__txt {
		@include l-sp(0);
	}
	span {
		content: "";
		inline-size: 5rem;
		block-size: 1px;
		background: transparent;
		display: block;
		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: $other_c1;
			@include transition;
			block-size: 1px;
			inline-size: 100%;
			animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
		}
	}
}

@keyframes scroll-line {
	0% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	51% {
		transform: scale(1, 1);
		transform-origin: 0 100%;
	}
	100% {
		transform: scale(1, 0);
		transform-origin: 0 100%;
	}
}
/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
	background: $white;
	padding-top: clamp(3em, 7vw, 7rem);
}

/*--------------------------------------------------------------------/
	aboutu us
/--------------------------------------------------------------------*/

// layout1
$top_aboutus_txt_pc: 50%; //テキスト　PC幅
$top_aboutus_img_pc: 47.5%; //画像　PC幅
$top_aboutus_between_pc: 100% - $top_aboutus_txt_pc - $top_aboutus_img_pc;
.top-aboutus {
	position: relative;
	margin-bottom: clamp(6rem, 12vw, 12rem);
	&__inner {
		position: relative;
		display: flex;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(lg) {
			column-gap: $top_aboutus_between_pc;
		}
		@include media-breakpoint-down(md) {
			@include m-a;
			flex-direction: column-reverse;
			row-gap: clamp(1.5em, 2vw, 2rem);
			padding-left: map-get($contmargin, tb);
			padding-right: map-get($contmargin, tb);
		}
		@include media-breakpoint-down(sm) {
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
	}
}

.top-aboutus__txt {
	position: relative;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(lg) {
		width: $top_aboutus_txt_pc;
		@include flex-end;
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			width: min(50vw, calc(696px + map-get($contmargin, pc)));
			padding-left: 5vw;
		}
		@include media-breakpoint-up(xl) {
			padding-left: map-get($contmargin, pc);
		}
		@include media-breakpoint-up(full) {
			width: 41.25vw;
		}
	}
	&__add {
		position: absolute;
		z-index: z-index(module, part04);
		@include media-breakpoint-up(lg) {
			top: -5vw;
			left: calc(100% - 5vw);
			width: clamp(40px, 12vw, 230px);
		}
		@include media-breakpoint-down(md) {
			top: -2vw;
			right: -6%;
			width: clamp(40px, 16vw, 360px);
		}
		@include media-breakpoint-down(xs) {
			top: -5vw;
		}
	}
	&__tit {
		margin-bottom: clamp(1.5em, 2vw, 2em);
		@include line-h(1.2);
		em {
			@include f-s_xxs(2, 40);
			font-style: normal;
			@include f-w(400);
			position: relative;
			@include f-family(font02);
			color: $other_c1;
			display: inline-block;
			@include line-h(1.2);
			@include l-sp(0.05em);
			padding-top: 0.4em;
			padding-right: 1.4em;
			@include media-breakpoint-up(xl) {
				padding-top: 0.5em;
				padding-right: 2em;
				@include f-size(64);
			}
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				width: clamp(36px, 6vw, 110px);
				height: clamp(30px, 5vw, 80px);
				background: url(../img/common/heart.svg) center center/contain no-repeat;
				@include media-breakpoint-up(xl) {
					width: 110px;
					height: 80px;
				}
			}
		}
		b {
			@include f-s_xxs(1, 16);
			@include f-w(700);
			display: block;
			color: $main_c;
			margin-top: 0.2em;
			@include media-breakpoint-up(xxl) {
				@include f-size(30);
			}
		}
	}
	&__txt {
		@include line-h(2);
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
		strong {
			@include f-w(500);
			font-size: 1.14em;
			@include line-h(1.5);
			color: $main_c;
			@include media-breakpoint-up(md) {
				font-size: 1.35em;
			}
		}
	}
}

.top-aboutus__img {
	position: relative;
	@include media-breakpoint-up(lg) {
		width: $top_aboutus_img_pc;
	}
	@include media-breakpoint-down(md) {
	}
	&__inner {
		@include radius_s;
		overflow: hidden;
		@include media-breakpoint-up(lg) {
			@include aspect-img(19, 14);
		}
		@include media-breakpoint-down(md) {
			@include aspect-img();
		}
	}
	img {
		width: 100%;
		display: block;
	}
}

/*--------------------------------------------------------------------/
	pickup 01
/--------------------------------------------------------------------*/
.pickup01 {
	padding-bottom: clamp(6rem, 10vw, 12rem);
	position: relative;
	&::before {
		position: absolute;
		top: 2em;
		left: 0;
		content: "";
		width: 100%;
		height: calc(100% - 2em);
		display: block;
		//background: $bg_c3;
		@include media-breakpoint-up(md) {
			top: 3em;
			height: calc(100% - 3em);
		}
	}
	&-inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&-tit {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		position: relative;
		b {
			@include f-family(font02);
			color: $main_c;
			@include f-s_xxs(1.75, 36);
			@include f-w(400);
			position: relative;
			display: inline-block;
			@include line-h(1.2);
			@include l-sp(0.05em);
			padding-top: 0.3em;
			padding-right: 1.2em;
			margin-bottom: 0.2em;
			@include media-breakpoint-up(lg) {
				padding-right: 1.4em;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				width: clamp(24px, 4vw, 58px);
				height: clamp(30px, 4.51vw, 65px);
				background: url(../img/common/tit-light_pink.svg) center center/contain no-repeat;
				@include media-breakpoint-up(xl) {
					width: 58px;
					height: 65px;
				}
			}
		}
		small {
			@include f-family(font01);
			@include f-s_xxs(1, 12);
			color: $other_c2;
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&-btn {
		margin-top: clamp(4rem, 7vw, 6rem);
	}
}

.pickup01-intro {
	margin-bottom: clamp(3rem, 4vw, 4rem);
	&__flex {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-around;
			& > * {
				flex-basis: 40.5%;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		.pickup01-intro__box__tit {
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.5, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
}

.pickup01-intro__box {
	&__inner {
		width: 100%;
		position: relative;
	}
	&__tit {
		@include f-w(700);
		margin-bottom: calc(1rem + 0.5em);
		@include f-s_xxs(1, 24);
		@include media-breakpoint-up(xxl) {
			@include f-size(40);
		}
	}
	&__txt {
		@include media-breakpoint-up(xl) {
			@include f-size(18);
		}
	}
}

.pickup01-list2 {
	display: flex;
	@include m-a;
	row-gap: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
		column-gap: 4%;
	}
	@include media-breakpoint-up(md) {
		column-gap: 8%;
	}
	@include media-breakpoint-up(lg) {
		column-gap: 4%;
		max-width: 100%;
		row-gap: clamp(3rem, 7vw, 7rem);
	}
	@include media-breakpoint-up(xl) {
		column-gap: 8%;
	}
	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}
	& > * {
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		@include media-breakpoint-up(md) {
			width: 46%;
		}
		@include media-breakpoint-up(lg) {
			width: 48%;
		}
		@include media-breakpoint-up(xl) {
			width: 46%;
		}
	}
}

.pickup01-list3 {
	display: flex;
	@include m-a;
	row-gap: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
		column-gap: 4%;
	}
	@include media-breakpoint-up(md) {
		column-gap: 8%;
	}
	@include media-breakpoint-up(lg) {
		column-gap: 1.5%;
	}
	@include media-breakpoint-up(xl) {
		column-gap: 4%;
		max-width: 100%;
	}
	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}
	& > * {
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		@include media-breakpoint-up(md) {
			width: 46%;
		}
		@include media-breakpoint-up(lg) {
			width: 32.33%;
		}
		@include media-breakpoint-up(xl) {
			width: 30.66%;
		}
	}
}

.pickup01-list4 {
	display: flex;
	@include m-a;
	row-gap: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
		column-gap: 4%;
	}
	@include media-breakpoint-up(md) {
		column-gap: 8%;
	}
	@include media-breakpoint-up(xl) {
		column-gap: 3.48%;
		max-width: 100%;
	}
	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}
	& > * {
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		@include media-breakpoint-up(md) {
			width: 46%;
		}
		@include media-breakpoint-up(xl) {
			width: 22.39%;
		}
	}
}

.pickup01-box {
	&:nth-of-type(odd) {
		.pickup01-box__tit::after {
			background-image: url(../img/common/heart_pink.svg);
		}
	}
	&:nth-of-type(even) {
		.pickup01-box__tit::after {
			background-image: url(../img/common/heart_blue.svg);
		}
	}
	&__inner {
		@include flex-column;
		background: $white;
		height: 100%;
		@include radius_s;
		overflow: hidden;
	}
	&__tit {
		@include f-w(700);
		@include line-h(1.4);
		@include f-s_xxs(1.14, 8);
		margin-bottom: 0.75em;
		text-align: center;
		color: $other_c2;
		padding-bottom: 2em;
		position: relative;
		@include media-breakpoint-up(xxl) {
			@include f-size(20);
		}
		&::after {
			position: absolute;
			left: 50%;
			bottom: 0;
			translate: -50% 0;
			content: "";
			width: 40px;
			height: 28px;
			background: center center/contain no-repeat;
		}
	}
	.pickup01-list3 &__tit {
		@include media-breakpoint-up(sm) {
			@include f-s_sm(1, 8);
		}
		@include media-breakpoint-up(lg) {
			@include f-s_lg(1.14, 2);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(20);
		}
	}
	.pickup01-list4 &__tit {
		@include media-breakpoint-up(sm) {
			@include f-s_sm(1, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-s_xl(1.14, 2);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(20);
		}
	}
	&__img {
		order: 1;
		width: 100%;
		&__inner {
			@include aspect-img;
			margin: 0;
		}
	}
	&__txt {
		order: 2;
		padding: 1.5em 7% 2em;
		@include media-breakpoint-up(xl) {
			padding: 2em 10% 2.5em;
		}
	}
	.btn-wrap {
		margin-top: 1.5em;
	}
	&__btn {
		@include btn_base;
		color: $txt_c;
		background-color: $white;
		border: 1px solid $other_c1;
		padding: 1em 4%;
		width: 100%;
		@include radius(999px);
		@include icon(arrow1_right, after);
		&:hover {
			background-color: $other_c1;
			border-color: $other_c1;
			color: $white;
			&::after {
				color: $white;
			}
		}
		&::after {
			position: absolute;
			right: 0.5em;
			top: 50%;
			font-size: 1.25em;
			transform: translateY(-50%);
			color: $other_c1;
			@include transition;
		}
	}
}

/*--------------------------------------------------------------------/
	pickup 02
/--------------------------------------------------------------------*/
.pickup02 {
	margin-bottom: clamp(3em, 6vw, 6rem);
	&-wrap {
		position: relative;
		overflow: hidden;
		padding-top: clamp(2em, 4vw, 4em);

		@include media-breakpoint-up(md) {
			margin-bottom: 4em;
		}
		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 60%;
			@include media-breakpoint-up(sm) {
				width: 98%;
			}
			@include media-breakpoint-up(xl) {
				width: 95%;
			}
		}
	}
	&-inner {
		position: relative;
		width: 100%;
		z-index: z-index(module, part02);
		padding: clamp(3rem, 7vw, 7rem) 0 clamp(3em, 6vw, 6em);
		@include media-breakpoint-up(lg) {
			padding: clamp(4rem, 9vw, 9rem) 0;
		}
	}
	& > *:nth-of-type(odd) {
		.pickup02-wrap {
			&::before {
				top: 0;
				right: 0;
				background: $bg_c1;
				@include media-breakpoint-up(sm) {
					border-top-left-radius: clamp(12px, 5.65vw, 90px);
					border-bottom-left-radius: clamp(12px, 5.65vw, 90px);
				}
			}
		}
		.pickup02-img {
			@include media-breakpoint-between(sm, md) {
				@include flex-r-reverse;
			}
			@include media-breakpoint-up(lg) {
				right: 0;
			}
			@include media-breakpoint-down(xs) {
				margin-left: 10%;
			}
			&__inner {
				overflow: hidden;
				@include media-breakpoint-up(sm) {
					border-top-left-radius: clamp(6px, 3.25vw, 52px);
					border-bottom-left-radius: clamp(6px, 3.25vw, 52px);
				}
				@include media-breakpoint-down(xs) {
					@include radius_m;
				}
			}
		}
		.pickup02-txt__tit {
			em {
				color: $other_c1;
				&::after {
					background-image: url(../img/common/tit-light_brown.svg);
				}
			}
		}
	}
	& > *:nth-of-type(even) {
		.pickup02-wrap {
			&::before {
				top: 0;
				left: 0;
				background: $bg_c2;
				@include media-breakpoint-up(sm) {
					border-top-right-radius: clamp(12px, 5.65vw, 90px);
					border-bottom-right-radius: clamp(12px, 5.65vw, 90px);
				}
			}
		}
		.pickup02-img {
			@include media-breakpoint-up(sm) {
				left: 0;
			}
			@include media-breakpoint-down(xs) {
				margin-left: 10%;
			}
			&__inner {
				overflow: hidden;
				@include media-breakpoint-up(sm) {
					border-top-right-radius: clamp(6px, 3.25vw, 52px);
					border-bottom-right-radius: clamp(6px, 3.25vw, 52px);
				}
				@include media-breakpoint-down(xs) {
					@include radius_m;
				}
			}
		}
		.pickup02-txt__tit {
			em {
				color: $sub_c;
				&::after {
					background-image: url(../img/common/tit-light_blue.svg);
				}
			}
		}
		.l-cont {
			@include flex-r-reverse;
		}
	}
}

.pickup02-img {
	@include media-breakpoint-up(lg) {
		position: absolute;
		top: 0;
		width: 50%;
		height: 100%;
	}
	@include media-breakpoint-up(xl) {
		width: 48%;
	}

	@include media-breakpoint-down(xs) {
		width: 80%;
	}
	&__wrap {
		@include media-breakpoint-up(lg) {
			width: 100%;
			height: 100%;
		}
		@include media-breakpoint-down(md) {
			width: 85%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
		}
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			margin: 0;
			position: relative;
			width: 100%;
			height: 100%;
			div {
				width: 100%;
				height: 100%;
			}
		}
		@include media-breakpoint-down(md) {
			@include aspect-img(16, 9);
		}
		@include media-breakpoint-down(sm) {
			@include aspect-img;
		}
	}
}

.pickup02-txt {
	width: 100%;
	background: $white;
	box-shadow: 0 0 10px rgba($black, 0.1);
	position: relative;
	z-index: z-index(module, part02);
	@include radius_m;
	@include media-breakpoint-up(lg) {
		width: 60%;
	}
	@include media-breakpoint-up(xl) {
		width: 66.66%;
	}
	@include media-breakpoint-down(md) {
		margin-top: clamp(-3rem, -9vw, -8rem);
	}
	&__inner {
		padding: clamp(2em, 3vw, 3rem) 8%;
		@include media-breakpoint-up(md) {
			padding: clamp(3em, 5vw, 5em) 12% clamp(3em, 5vw, 5em);
		}
		@include media-breakpoint-up(xl) {
			padding: clamp(3em, 6vw, 6em) 16% clamp(3em, 6vw, 6em) 12%;
		}
	}
	&__tit {
		@include line-h(1.2);
		margin-bottom: clamp(1em, 2vw, 2rem);
		em {
			@include f-family(font02);
			@include f-w(500);
			@include l-sp(0.02);
			font-style: normal;
			display: inline-block;
			@include f-s_xxs(2, 40);
			margin-bottom: 0.2em;
			position: relative;
			padding-right: 1.2em;
			@include media-breakpoint-up(md) {
				@include f-s_md(2, 30);
			}
			@include media-breakpoint-up(lg) {
				padding-right: 1.4em;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				width: clamp(24px, 4vw, 58px);
				height: clamp(30px, 4.51vw, 65px);
				background: center center/contain no-repeat;
				@include media-breakpoint-up(xl) {
					width: 58px;
					height: 65px;
				}
			}
		}
		b {
			@include f-family(font01);
			@include f-w(700);
			display: block;
			@include f-s_xxs(1, 16);
			color: $other_c2;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.25, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(30);
			}
		}
	}
	&__catch {
		margin-bottom: clamp(0.5em, 1vw, 0.75em);
		@include f-s_xxs(1, 6);
		@include line-h(1.5);
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		strong {
			position: relative;
			color: $txt_c;
			padding-right: 0.5em;
		}
	}
}

/*--------------------------------------------------------------------/
	top works
/--------------------------------------------------------------------*/
.top-works {
	//margin-top: clamp(6rem, 10vw, 10rem);
	padding-top: clamp(5rem, 9vw, 9rem);
	padding-bottom: clamp(6rem, 10vw, 12rem);
	position: relative;
	overflow: hidden;
	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 60%;
		max-height: 440px;
		z-index: z-index(module, part01);
		width: 100%;
		&__inner {
			position: relative;
			max-height: 440px;
			overflow: hidden;
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		em {
			@include f-family(font02);
			@include f-s_xxs(2.25, 40);
			@include f-w(500);
			padding-right: clamp(3rem, 6vw, 6rem);
			position: relative;
			font-style: normal;
			color: $other_c1;
			display: inline-block;
			@include media-breakpoint-up(xl) {
				@include f-size(80);
			}
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				width: clamp(24px, 4vw, 58px);
				height: clamp(30px, 4.51vw, 65px);
				background: url(../img/common/tit-light_brown.svg) center center/contain no-repeat;
				@include media-breakpoint-up(xl) {
					width: 58px;
					height: 65px;
				}
			}
		}
		b {
			@include f-family(font01);
			@include f-s_xxs(1, 16);
			color: $other_c2;
			display: block;
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	&__list {
		.is-column {
			margin-bottom: clamp(2rem, 5vw, 5rem);
		}
	}
	&__btn {
		@include m-a;
		//@include media-breakpoint-up(lg) {
		//	position: absolute;
		//	top: 5rem;
		//	right: 0;
		//	width: 30%;
		//}
		a {
			@include btn_arrow($white, $main_c, $main_c, $white, $white, $hover_c, $hover_c, $white);
			//@include media-breakpoint-up(lg) {
			//	@include btn_arrow($white, transparent, $white, $white, $hover_c, $hover_c);
			//	border-width: 2px;
			//}
			@include media-breakpoint-down(md) {
				@include btn_arrow($white, $main_c, $main_c, $white, $white, $hover_c, $hover_c, $white);
			}
		}
	}
}

.top-works {
	position: relative;
	// swiper custom
	.swiper {
		width: 100%;
		overflow: visible;
		position: relative;
		//padding-bottom: clamp(5rem, 8vw, 8rem);
		margin-bottom: 5rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 7rem;
		}
	}
	.swiper-control {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: map-get($grid-num, xl) px;
		@include media-breakpoint-up(full) {
			max-width: floor-decimal(calc(map-get($grid-num, xl) / map-get($grid-num, full)), 4) * 100vw;
		}
	}
	.swiper-next_prev {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: calc(100% + 32px);
		left: -16px;
		top: 50%;
		z-index: z-index(module, part01);
		transform: translateY(-50%);
		@include media-breakpoint-up(md) {
			width: calc(100% + 64px);
			left: -32px;
		}
		@include media-breakpoint-up(lg) {
			width: calc(100% + 90px);
			left: -45px;
		}
	}
	.swiper-button-prev {
		left: 0;
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		float: none;
		display: block;
		background-image: none;
		width: 48px;
		height: 48px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			width: 64px;
			height: 64px;
		}
		@include media-breakpoint-up(lg) {
			width: 90px;
			height: 90px;
		}
		&::before {
			content: "";
			position: absolute;
			background: rgba($white, 0.5);
			border: 1px solid $other_c1;
			@include transition;
			width: 100%;
			height: 100%;
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			color: $other_c1;
			font-size: 1.5em;
			@include transition;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include f-size(48);
			}
		}
		&:hover {
			&::before {
				background: $main_c;
				border-color: $main_c;
			}
			&::after {
				color: $white;
			}
		}
	}
	.swiper-button-prev {
		@include icon(arrow2_left, after);
		&::after {
			transform: translate(-60%, -50%);
		}
	}
	.swiper-button-next {
		@include icon(arrow2_right, after);

		&::after {
			transform: translate(-40%, -50%);
		}
	}
	.swiper-scrollbar {
		position: absolute;
		top: calc(100% + 2rem);
		width: 100%;
		height: 6px;
		z-index: z-index(module, part01);
		background: $p-gray;
		@include media-breakpoint-up(lg) {
			height: 8px;
			top: calc(100% + 3rem);
		}
	}
	.swiper-scrollbar-drag {
		background: $m-gray;
		height: 6px;
		@include transition;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			height: 8px;
		}
		&:hover {
			background-color: $main_c;
		}
	}
}

/*--------------------------------------------------------------------/
	top topnews
/--------------------------------------------------------------------*/
.top-topnews {
	padding: clamp(6rem, 12vw, 12rem) 0;
	background: $bg_c5;
	overflow: hidden;
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
		em {
			@include f-family(font02);
			color: $black;
			@include f-s_xxs(3, 70);
			@include f-w(500);
			padding-right: clamp(3rem, 6vw, 6rem);
			position: relative;
			font-style: normal;
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(120);
			}
		}
		b {
			@include f-family(font01);
			@include f-s_xxs(1, 16);
			color: $main_c;
			display: block;
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	&__table {
		max-height: 520px;
		overflow-y: auto;
		@include scroll-bar;
		@include media-breakpoint-up(lg) {
			width: 65.55%;
			margin-top: clamp(2rem, 3vw, 3rem);
		}
		&__inner {
			tr {
				display: block;
				border-bottom: 1px solid $m-gray;
				&:first-child {
					border-top: 1px solid $m-gray;
				}
			}
			th {
				padding: 1.25em 0;
				min-width: 100px;
				text-align: left;
				@include l-sp(0);
				color: $main_c;
				@include f-family(font02);
				@include f-w(500);
				@include media-breakpoint-up(sm) {
					width: 16%;
				}
				@include media-breakpoint-up(xl) {
					@include f-size(19);
				}
				@include media-breakpoint-down(xs) {
					width: 100%;
					display: block;
					padding: 1.25em 0 0 0;
					@include f-size(16);
				}
			}
			td {
				padding: 1.25em 0;
				@include media-breakpoint-up(sm) {
					width: 84%;
				}
				@include media-breakpoint-down(xs) {
					width: 100%;
					display: block;
					padding: 0 0 1.25em 0;
				}
			}
		}
	}
}

/*--------------------------------------------------------------------/
	top feed
/--------------------------------------------------------------------*/
.top-feed {
	padding: 0 0 clamp(6em, 12vw, 12rem);
	&__flex {
		display: flex;
		@include media-breakpoint-up(md) {
			justify-content: space-between;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: clamp(3em, 6vw, 6em);
		}
	}
}

.top-feed__box {
	padding-bottom: clamp(6em, 7vw, 8em);
	position: relative;
	@include media-breakpoint-up(md) {
		padding-bottom: 8em;
		width: 48%;
	}
	&__tit {
		margin-bottom: clamp(1em, 1.75vw, 1.5em);
		position: relative;
		b {
			@include f-family(font02);
			color: $other_c1;
			@include f-s_xxs(1.75, 36);
			@include f-w(400);
			position: relative;
			display: inline-block;
			@include line-h(1.2);
			@include l-sp(0.05em);
			padding-top: 0.3em;
			padding-right: 1.2em;
			margin-bottom: 0.2em;
			@include media-breakpoint-up(md) {
				@include f-s_md(2, 30);
			}
			@include media-breakpoint-up(lg) {
				padding-right: 1.4em;
			}
			@include media-breakpoint-up(xl) {
				@include f-size(60);
			}
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				content: "";
				width: clamp(24px, 4vw, 58px);
				height: clamp(30px, 4.51vw, 65px);
				background: url(../img/common/tit-light_brown.svg) center center/contain no-repeat;
				@include media-breakpoint-up(xl) {
					width: 58px;
					height: 65px;
				}
			}
		}
		small {
			@include f-family(font01);
			@include f-s_xxs(1, 12);
			color: $other_c2;
			display: block;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.14, 8);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(28);
			}
		}
	}
	&__list {
		width: 100%;
		overflow-y: auto;
		max-height: 400px;
		border-top: 1px solid $l-gray;
		padding-right: 1em;
		@include media-breakpoint-up(xl) {
			max-height: 560px;
		}
	}
	.btn-wrap {
		position: absolute;
		width: 80%;
		left: 10%;
		bottom: 0;
		@include m-a;

		@include media-breakpoint-up(lg) {
			left: 20%;
			width: 60%;
		}
	}
}

.top-feed__item {
	a {
		position: relative;
		@include flex-column;
		padding: 0.75em 0 1em;
		@include dec-none;
		color: $txt_c;
		border-bottom: 1px solid $l-gray;
		&::before {
			position: absolute;
			bottom: -1px;
			left: 0;
			content: "";
			width: 0;
			height: 1px;
			background: $main_c;
			@include transition;
			z-index: z-index(module, part01);
		}
		&:hover {
			&::before {
				width: 100%;
			}
		}
	}
	&__tit {
		order: 3;
		@include f-w(500);
	}
	&__date {
		order: 1;
		font-size: 0.94em;
		@include line-h(1.4);
		margin-bottom: 0.2em;
	}
	&__category {
		order: 2;
		@include flex-wrap;
		margin-bottom: 0.4em;
		gap: 0.5em 0.4em;
		.category-item {
			font-size: 0.75em;
		}
	}
}

// top-commonpost1
//--------------------------------------------------------------------*/

// layout1
$top_post1_newest_tb: 43.66%; //最新記事　タブレット幅
$top_post1_newest_pc: 41.66%; //最新記事　PC幅
$top_post1_list_tb: 50.34%; //記事リスト　タブレット幅
$top_post1_list_pc: 48.34%; //記事リスト　タブレット幅
$top_post1_between_tb: 100% - $top_post1_newest_tb - $top_post1_list_tb;
$top_post1_between_pc: 100% - $top_post1_newest_pc - $top_post1_list_pc;

.top-commonpost1 {
	width: 100%;
	display: flex;
	position: relative;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(md) {
		column-gap: $top_post1_between_tb;
	}
	@include media-breakpoint-up(xl) {
		column-gap: $top_post1_between_pc;
	}
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		row-gap: 1em;
	}
	@include media-breakpoint-down(xs) {
		row-gap: clamp(2em, 3vw, 3rem);
	}
	&::after {
		@include media-breakpoint-up(md) {
			content: "";
			width: 1px;
			height: 100%;
			position: absolute;
			top: 0;
			left: calc($top_post1_newest_tb + ($top_post1_between_tb / 2));
			background: $l-gray;
		}
		@include media-breakpoint-up(xl) {
			left: calc($top_post1_newest_pc + ($top_post1_between_pc / 2));
		}
	}
}

.top-commonpost1__newest {
	@include media-breakpoint-up(md) {
		width: $top_post1_newest_tb;
	}
	@include media-breakpoint-up(xl) {
		width: $top_post1_newest_pc;
	}
}

.top-commonpost1__newest__box {
	a {
		display: block;
		@include dec-none;
		color: $txt_c;
		@include transition;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		overflow: hidden;
		@include media-breakpoint-up(md) {
			flex-direction: column-reverse;
		}
		@include media-breakpoint-only(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-only(sm) {
			width: 35.91%;
		}
		&__inner {
			@include aspect-img;
			margin: 0;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			padding: 0.5em 1.5em 1.5em;
		}
		@include media-breakpoint-only(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
	}
	&__date {
		@include f-family(font02);
		@include l-sp(0);
		@include f-w(500);
		color: $main_c;
		@include media-breakpoint-up(md) {
			font-size: 1.15em;
		}
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		@include media-breakpoint-up(md) {
			margin-bottom: calc(0.5rem + 0.3em);
		}
		.category-item {
			font-size: 0.7em;
			@include media-breakpoint-up(xl) {
				font-size: 0.8em;
			}
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xxs(0.94, 6);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(md) {
			@include f-s_md(0.9, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	.txt {
		@include media-breakpoint-up(md) {
			font-size: 0.9em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(16);
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.top-commonpost1__list {
	@include flex-column;
	@include media-breakpoint-up(md) {
		row-gap: 1em;
		width: $top_post1_list_tb;
		justify-content: space-between;
	}
	@include media-breakpoint-up(xl) {
		width: $top_post1_list_pc;
	}
	@include media-breakpoint-down(xs) {
		row-gap: clamp(2em, 3vw, 3rem);
	}
}

.top-commonpost1__list__box {
	position: relative;
	text-align: left;
	width: 100%;
	@include media-breakpoint-up(md) {
		height: 100%;
	}
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		color: $txt_c;
		overflow: hidden;
		@include transition;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 35.91%;
		}
		&__inner {
			margin: 0;
			@include media-breakpoint-up(sm) {
				height: 100%;
				& > div {
					height: 100%;
				}
			}
			@include media-breakpoint-down(xs) {
				@include aspect-img;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
	}
	&__date {
		@include f-family(font02);
		@include f-w(500);
		@include l-sp(0);
		color: $main_c;
	}
	&__tit {
		margin-bottom: 0.5em;
		@include media-breakpoint-up(md) {
			@include f-s_md(0.9, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(16);
		}
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		row-gap: 0.2em;
		.category-item {
			font-size: 0.7em;
		}
	}
}

// top-commonpost2
//--------------------------------------------------------------------*/
.top-commonpost2 {
	&__list {
		display: flex;
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			gap: 1.5em 2%;
		}
		@include media-breakpoint-up(xl) {
			gap: 2em 3%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1em;
		}
		@include media-breakpoint-down(xs) {
			row-gap: clamp(2em, 3vw, 3rem);
		}
		& > * {
			@include media-breakpoint-up(md) {
				width: 49%;
			}
			@include media-breakpoint-up(xl) {
				width: 48.5%;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}

.top-commonpost2__box {
	position: relative;
	background: $white;
	text-align: left;
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		@include transition;
		color: $txt_c;
		overflow: hidden;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 35.91%;
		}
		@include media-breakpoint-up(md) {
			width: 25%;
		}
		@include media-breakpoint-up(xl) {
			width: 240px;
		}
		&__inner {
			margin: 0;
			@include media-breakpoint-up(sm) {
				height: 100%;
				& > div {
					height: 100%;
				}
			}
			@include media-breakpoint-down(xs) {
				@include aspect-img;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-up(md) {
			width: 75%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(100% - 240px);
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
		&__inner {
			width: 100%;
		}
	}
	&__date {
		@include f-family(font02);
		@include f-w(500);
		@include l-sp(0);
		color: $main_c;
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		row-gap: 0.2em;
		.category-item {
			font-size: 0.7em;
		}
	}
	&__tit {
		@include f-s_xxs(1, 3);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(sm) {
			@include f-s_sm(1, 4);
		}
		@include media-breakpoint-up(md) {
			@include f-s_md(0.94, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(21);
		}
	}
}
