@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1753px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1024px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1752px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1023px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1023px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1024px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1752px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
.main {
  overflow: hidden;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
}
@media (min-width: 768px) {
  .main-view {
    height: calc(6em + 46vw);
  }
}
@media (min-width: 1553px) {
  .main-view {
    height: calc(6em + 740px);
  }
}
@media (min-width: 1921px) {
  .main-view {
    height: calc(6em + 38.5vw);
  }
}
@media (max-width: 767px) {
  .main-view {
    height: 141.33vw;
  }
}

.main-view__bg {
  position: relative;
  overflow: hidden;
  top: 5em;
}
@media (min-width: 768px) {
  .main-view__bg {
    top: 6em;
    height: 46vw;
  }
}
@media (min-width: 1553px) {
  .main-view__bg {
    height: 740px;
  }
}
@media (min-width: 1921px) {
  .main-view__bg {
    height: 38.5vw;
  }
}
@media (max-width: 767px) {
  .main-view__bg {
    height: calc(141.33vw - 5em);
  }
}
.main-view__bg__inner {
  height: 100%;
}
.main-view__bg__img {
  position: absolute;
  right: 0;
  width: 95%;
  height: 100%;
  z-index: 1;
  border-top-left-radius: clamp(12px, 5.65vw, 90px);
  border-bottom-left-radius: clamp(12px, 5.65vw, 90px);
  overflow: hidden;
}
.main-view__bg__img__inner {
  height: 100%;
}

.main-view__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.main-view__inner__txt {
  position: relative;
  height: 100%;
  width: 95%;
  left: 5%;
}
@media (min-width: 768px) {
  .main-view__inner__txt {
    overflow: hidden;
  }
}
.main-view__inner__txt__inner {
  height: 100%;
}
.main-view__inner .l-cont {
  height: 100%;
}

.main-view__catch {
  position: absolute;
  top: 26%;
  left: -1px;
  z-index: 1;
  color: #000000;
  width: 90%;
}
@media (min-width: 768px) {
  .main-view__catch {
    top: 22%;
    width: 60%;
  }
}
@media (min-width: 1553px) {
  .main-view__catch {
    top: 18%;
    width: 49.25%;
  }
}

.main-view__representative {
  position: absolute;
  bottom: 3.5%;
  max-width: 240px;
  right: -18%;
  width: 62vw;
  z-index: 2;
}
@media (min-width: 376px) {
  .main-view__representative {
    max-width: none;
    width: 62vw;
    right: -16%;
  }
}
@media (min-width: 768px) {
  .main-view__representative {
    right: 6%;
    bottom: 0;
    width: 32vw;
  }
}
@media (min-width: 1024px) {
  .main-view__representative {
    right: 6%;
    width: 32vw;
  }
}
@media (min-width: 1553px) {
  .main-view__representative {
    right: 14%;
    width: 530px;
  }
}
@media (min-width: 1921px) {
  .main-view__representative {
    width: 15.1vw;
  }
}
.main-view__representative img {
  filter: drop-shadow(0px 0px 8px #ffffff);
}

.main-view__name {
  position: absolute;
  z-index: 2;
  background: #ffffff;
  border-top-left-radius: clamp(6px, 2vw, 26px);
  border-bottom-right-radius: clamp(6px, 2vw, 26px);
}
@media (min-width: 768px) {
  .main-view__name {
    bottom: 8%;
    right: 2%;
    max-width: none;
    width: 18vw;
  }
}
@media (min-width: 1024px) {
  .main-view__name {
    width: 20vw;
  }
}
@media (min-width: 1553px) {
  .main-view__name {
    width: 290px;
  }
}
@media (min-width: 1921px) {
  .main-view__name {
    width: 15.1vw;
  }
}
@media (max-width: 767px) {
  .main-view__name {
    width: 46vw;
    bottom: -2.5em;
    right: 0;
    padding-left: 2%;
    padding-right: 8%;
  }
}

.main-view__btn {
  position: absolute;
  left: 0;
  bottom: 9%;
  z-index: 4;
  width: 90%;
}
@media (min-width: 576px) {
  .main-view__btn {
    bottom: 11%;
    width: 85%;
  }
}
@media (min-width: 768px) {
  .main-view__btn {
    left: 2%;
    bottom: 7%;
    width: 58%;
  }
}
@media (min-width: 1553px) {
  .main-view__btn {
    bottom: 5%;
    left: 5%;
    width: 44%;
  }
}
@media (min-width: 1921px) {
  .main-view__btn {
    width: 79.16%;
    max-width: none;
  }
}
.main-view__btn__inner {
  position: relative;
  width: 100%;
  aspect-ratio: 5/2.1;
}
.main-view__btn__bg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}
.main-view__btn__list {
  display: flex;
  width: 82%;
  column-gap: 3.71%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 3;
}
.main-view__btn__list > * {
  width: 30.86%;
}
.main-view__btn .btn-wrap a img {
  transition: all 0.3s ease 0s;
}
.main-view__btn .main-view__btn__01 a:hover img {
  filter: drop-shadow(0px 0px 8px #3AA6B9);
}
.main-view__btn .main-view__btn__02 a:hover img {
  filter: drop-shadow(0px 0px 8px #C19F8A);
}
.main-view__btn .main-view__btn__03 a:hover img {
  filter: drop-shadow(0px 0px 8px #DA697F);
}

/* scroll */
.scroll-line {
  position: absolute;
  left: min(1.5%, 3rem);
  bottom: 0;
  writing-mode: vertical-rl;
  z-index: 4;
}
@media (max-width: 767px) {
  .scroll-line {
    display: none;
  }
}
.scroll-line a {
  text-decoration: none !important;
  font-family: 'Zen Old Mincho', sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #5A5141;
  transition: all 0.3s ease 0s;
}
.scroll-line a:hover {
  color: #eb234a;
}
.scroll-line a:hover span::before {
  background: #eb234a;
}
.scroll-line__txt {
  letter-spacing: 0;
}
.scroll-line span {
  content: "";
  inline-size: 5rem;
  block-size: 1px;
  background: transparent;
  display: block;
  position: relative;
  overflow: hidden;
}
.scroll-line span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: #C19F8A;
  transition: all 0.3s ease 0s;
  block-size: 1px;
  inline-size: 100%;
  animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes scroll-line {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  51% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
  background: #ffffff;
  padding-top: clamp(3em, 7vw, 7rem);
}

/*--------------------------------------------------------------------/
	aboutu us
/--------------------------------------------------------------------*/
.top-aboutus {
  position: relative;
  margin-bottom: clamp(6rem, 12vw, 12rem);
}
.top-aboutus__inner {
  position: relative;
  display: flex;
  z-index: 1;
}
@media (min-width: 1024px) {
  .top-aboutus__inner {
    column-gap: 2.5%;
  }
}
@media (max-width: 1023px) {
  .top-aboutus__inner {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column-reverse;
    row-gap: clamp(1.5em, 2vw, 2rem);
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media (max-width: 767px) {
  .top-aboutus__inner {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.top-aboutus__txt {
  position: relative;
  z-index: 2;
}
@media (min-width: 1024px) {
  .top-aboutus__txt {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 1024px) {
  .top-aboutus__txt__inner {
    width: min(50vw, 744px);
    padding-left: 5vw;
  }
}
@media (min-width: 1553px) {
  .top-aboutus__txt__inner {
    padding-left: 48px;
  }
}
@media (min-width: 1921px) {
  .top-aboutus__txt__inner {
    width: 41.25vw;
  }
}
.top-aboutus__txt__add {
  position: absolute;
  z-index: 4;
}
@media (min-width: 1024px) {
  .top-aboutus__txt__add {
    top: -5vw;
    left: calc(100% - 5vw);
    width: clamp(40px, 12vw, 230px);
  }
}
@media (max-width: 1023px) {
  .top-aboutus__txt__add {
    top: -2vw;
    right: -6%;
    width: clamp(40px, 16vw, 360px);
  }
}
@media (max-width: 575px) {
  .top-aboutus__txt__add {
    top: -5vw;
  }
}
.top-aboutus__txt__tit {
  margin-bottom: clamp(1.5em, 2vw, 2em);
  line-height: 1.2;
}
.top-aboutus__txt__tit em {
  font-size: calc(2rem + 40 * (100vw - 280px) / 1360);
  font-style: normal;
  font-weight: 400;
  position: relative;
  font-family: 'Zen Old Mincho', sans-serif;
  color: #C19F8A;
  display: inline-block;
  line-height: 1.2;
  letter-spacing: 0.05em;
  padding-top: 0.4em;
  padding-right: 1.4em;
}
@media (min-width: 1553px) {
  .top-aboutus__txt__tit em {
    padding-top: 0.5em;
    padding-right: 2em;
    font-size: 4rem;
  }
}
.top-aboutus__txt__tit em::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(36px, 6vw, 110px);
  height: clamp(30px, 5vw, 80px);
  background: url(../img/common/heart.svg) center center/contain no-repeat;
}
@media (min-width: 1553px) {
  .top-aboutus__txt__tit em::after {
    width: 110px;
    height: 80px;
  }
}
.top-aboutus__txt__tit b {
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  font-weight: 700;
  display: block;
  color: #DA697F;
  margin-top: 0.2em;
}
@media (min-width: 1753px) {
  .top-aboutus__txt__tit b {
    font-size: 1.88rem;
  }
}
.top-aboutus__txt__txt {
  line-height: 2;
}
@media (min-width: 1553px) {
  .top-aboutus__txt__txt {
    font-size: 1.14em;
  }
}
.top-aboutus__txt__txt strong {
  font-weight: 500;
  font-size: 1.14em;
  line-height: 1.5;
  color: #DA697F;
}
@media (min-width: 768px) {
  .top-aboutus__txt__txt strong {
    font-size: 1.35em;
  }
}

.top-aboutus__img {
  position: relative;
}
@media (min-width: 1024px) {
  .top-aboutus__img {
    width: 47.5%;
  }
}
.top-aboutus__img__inner {
  border-radius: clamp(6px, 2vw, 30px);
  overflow: hidden;
}
@media (min-width: 1024px) {
  .top-aboutus__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-aboutus__img__inner::before {
    content: "";
    display: block;
    padding-top: 73.68%;
    position: relative;
  }
  .top-aboutus__img__inner > div,
  .top-aboutus__img__inner figure,
  .top-aboutus__img__inner a,
  .top-aboutus__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .top-aboutus__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-aboutus__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .top-aboutus__img__inner > div,
  .top-aboutus__img__inner figure,
  .top-aboutus__img__inner a,
  .top-aboutus__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
.top-aboutus__img img {
  width: 100%;
  display: block;
}

/*--------------------------------------------------------------------/
	pickup 01
/--------------------------------------------------------------------*/
.pickup01 {
  padding-bottom: clamp(6rem, 10vw, 12rem);
  position: relative;
}
.pickup01::before {
  position: absolute;
  top: 2em;
  left: 0;
  content: "";
  width: 100%;
  height: calc(100% - 2em);
  display: block;
}
@media (min-width: 768px) {
  .pickup01::before {
    top: 3em;
    height: calc(100% - 3em);
  }
}
.pickup01-inner {
  position: relative;
  z-index: 2;
}
.pickup01-tit {
  margin-bottom: clamp(2rem, 3vw, 3rem);
  position: relative;
}
.pickup01-tit b {
  font-family: 'Zen Old Mincho', sans-serif;
  color: #DA697F;
  font-size: calc(1.75rem + 36 * (100vw - 280px) / 1360);
  font-weight: 400;
  position: relative;
  display: inline-block;
  line-height: 1.2;
  letter-spacing: 0.05em;
  padding-top: 0.3em;
  padding-right: 1.2em;
  margin-bottom: 0.2em;
}
@media (min-width: 1024px) {
  .pickup01-tit b {
    padding-right: 1.4em;
  }
}
@media (min-width: 1553px) {
  .pickup01-tit b {
    font-size: 3.75rem;
  }
}
.pickup01-tit b::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(24px, 4vw, 58px);
  height: clamp(30px, 4.51vw, 65px);
  background: url(../img/common/tit-light_pink.svg) center center/contain no-repeat;
}
@media (min-width: 1553px) {
  .pickup01-tit b::after {
    width: 58px;
    height: 65px;
  }
}
.pickup01-tit small {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1360);
  color: #5A5141;
  display: block;
}
@media (min-width: 1553px) {
  .pickup01-tit small {
    font-size: 1.75rem;
  }
}
.pickup01-btn {
  margin-top: clamp(4rem, 7vw, 6rem);
}

.pickup01-intro {
  margin-bottom: clamp(3rem, 4vw, 4rem);
}
.pickup01-intro__flex {
  display: flex;
}
@media (min-width: 1024px) {
  .pickup01-intro__flex {
    justify-content: space-around;
  }
  .pickup01-intro__flex > * {
    flex-basis: 40.5%;
  }
}
@media (max-width: 1023px) {
  .pickup01-intro__flex {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1024px) {
  .pickup01-intro__flex .pickup01-intro__box__tit {
    font-size: calc(1.5rem + 10 * (100vw - 1000px) / 640);
  }
}
@media (min-width: 1553px) {
  .pickup01-intro__flex .pickup01-intro__box__tit {
    font-size: 2.25rem;
  }
}

.pickup01-intro__box__inner {
  width: 100%;
  position: relative;
}
.pickup01-intro__box__tit {
  font-weight: 700;
  margin-bottom: calc(1rem + 0.5em);
  font-size: calc(1rem + 24 * (100vw - 280px) / 1360);
}
@media (min-width: 1753px) {
  .pickup01-intro__box__tit {
    font-size: 2.5rem;
  }
}
@media (min-width: 1553px) {
  .pickup01-intro__box__txt {
    font-size: 1.13rem;
  }
}

.pickup01-list2 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  row-gap: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 576px) {
  .pickup01-list2 {
    flex-wrap: wrap;
    column-gap: 4%;
  }
}
@media (min-width: 768px) {
  .pickup01-list2 {
    column-gap: 8%;
  }
}
@media (min-width: 1024px) {
  .pickup01-list2 {
    column-gap: 4%;
    max-width: 100%;
    row-gap: clamp(3rem, 7vw, 7rem);
  }
}
@media (min-width: 1553px) {
  .pickup01-list2 {
    column-gap: 8%;
  }
}
@media (max-width: 575px) {
  .pickup01-list2 {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .pickup01-list2 > * {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .pickup01-list2 > * {
    width: 46%;
  }
}
@media (min-width: 1024px) {
  .pickup01-list2 > * {
    width: 48%;
  }
}
@media (min-width: 1553px) {
  .pickup01-list2 > * {
    width: 46%;
  }
}

.pickup01-list3 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  row-gap: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 576px) {
  .pickup01-list3 {
    flex-wrap: wrap;
    column-gap: 4%;
  }
}
@media (min-width: 768px) {
  .pickup01-list3 {
    column-gap: 8%;
  }
}
@media (min-width: 1024px) {
  .pickup01-list3 {
    column-gap: 1.5%;
  }
}
@media (min-width: 1553px) {
  .pickup01-list3 {
    column-gap: 4%;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .pickup01-list3 {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .pickup01-list3 > * {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .pickup01-list3 > * {
    width: 46%;
  }
}
@media (min-width: 1024px) {
  .pickup01-list3 > * {
    width: 32.33%;
  }
}
@media (min-width: 1553px) {
  .pickup01-list3 > * {
    width: 30.66%;
  }
}

.pickup01-list4 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  row-gap: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 576px) {
  .pickup01-list4 {
    flex-wrap: wrap;
    column-gap: 4%;
  }
}
@media (min-width: 768px) {
  .pickup01-list4 {
    column-gap: 8%;
  }
}
@media (min-width: 1553px) {
  .pickup01-list4 {
    column-gap: 3.48%;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .pickup01-list4 {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .pickup01-list4 > * {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .pickup01-list4 > * {
    width: 46%;
  }
}
@media (min-width: 1553px) {
  .pickup01-list4 > * {
    width: 22.39%;
  }
}

.pickup01-box:nth-of-type(odd) .pickup01-box__tit::after {
  background-image: url(../img/common/heart_pink.svg);
}
.pickup01-box:nth-of-type(even) .pickup01-box__tit::after {
  background-image: url(../img/common/heart_blue.svg);
}
.pickup01-box__inner {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  height: 100%;
  border-radius: clamp(6px, 2vw, 30px);
  overflow: hidden;
}
.pickup01-box__tit {
  font-weight: 700;
  line-height: 1.4;
  font-size: calc(1.14rem + 8 * (100vw - 280px) / 1360);
  margin-bottom: 0.75em;
  text-align: center;
  color: #5A5141;
  padding-bottom: 2em;
  position: relative;
}
@media (min-width: 1753px) {
  .pickup01-box__tit {
    font-size: 1.25rem;
  }
}
.pickup01-box__tit::after {
  position: absolute;
  left: 50%;
  bottom: 0;
  translate: -50% 0;
  content: "";
  width: 40px;
  height: 28px;
  background: center center/contain no-repeat;
}
@media (min-width: 576px) {
  .pickup01-list3 .pickup01-box__tit {
    font-size: calc(1rem + 8 * (100vw - 576px) / 1064);
  }
}
@media (min-width: 1024px) {
  .pickup01-list3 .pickup01-box__tit {
    font-size: calc(1.14rem + 2 * (100vw - 1000px) / 640);
  }
}
@media (min-width: 1753px) {
  .pickup01-list3 .pickup01-box__tit {
    font-size: 1.25rem;
  }
}
@media (min-width: 576px) {
  .pickup01-list4 .pickup01-box__tit {
    font-size: calc(1rem + 8 * (100vw - 576px) / 1064);
  }
}
@media (min-width: 1553px) {
  .pickup01-list4 .pickup01-box__tit {
    font-size: calc(1.14rem + 2 * (100vw - 1440px) / 200);
  }
}
@media (min-width: 1753px) {
  .pickup01-list4 .pickup01-box__tit {
    font-size: 1.25rem;
  }
}
.pickup01-box__img {
  order: 1;
  width: 100%;
}
.pickup01-box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.pickup01-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.pickup01-box__img__inner > div,
.pickup01-box__img__inner figure,
.pickup01-box__img__inner a,
.pickup01-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pickup01-box__txt {
  order: 2;
  padding: 1.5em 7% 2em;
}
@media (min-width: 1553px) {
  .pickup01-box__txt {
    padding: 2em 10% 2.5em;
  }
}
.pickup01-box .btn-wrap {
  margin-top: 1.5em;
}
.pickup01-box__btn {
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-size: 1.07em;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #202020;
  background-color: #ffffff;
  border: 1px solid #C19F8A;
  padding: 1em 4%;
  width: 100%;
  border-radius: 999px;
}
.pickup01-box__btn:after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pickup01-box__btn:hover {
  background-color: #C19F8A;
  border-color: #C19F8A;
  color: #ffffff;
}
.pickup01-box__btn:hover::after {
  color: #ffffff;
}
.pickup01-box__btn::after {
  position: absolute;
  right: 0.5em;
  top: 50%;
  font-size: 1.25em;
  transform: translateY(-50%);
  color: #C19F8A;
  transition: all 0.3s ease 0s;
}

/*--------------------------------------------------------------------/
	pickup 02
/--------------------------------------------------------------------*/
.pickup02 {
  margin-bottom: clamp(3em, 6vw, 6rem);
}
.pickup02-wrap {
  position: relative;
  overflow: hidden;
  padding-top: clamp(2em, 4vw, 4em);
}
@media (min-width: 768px) {
  .pickup02-wrap {
    margin-bottom: 4em;
  }
}
.pickup02-wrap::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 60%;
}
@media (min-width: 576px) {
  .pickup02-wrap::before {
    width: 98%;
  }
}
@media (min-width: 1553px) {
  .pickup02-wrap::before {
    width: 95%;
  }
}
.pickup02-inner {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: clamp(3rem, 7vw, 7rem) 0 clamp(3em, 6vw, 6em);
}
@media (min-width: 1024px) {
  .pickup02-inner {
    padding: clamp(4rem, 9vw, 9rem) 0;
  }
}
.pickup02 > *:nth-of-type(odd) .pickup02-wrap::before {
  top: 0;
  right: 0;
  background: #FAF6F4;
}
@media (min-width: 576px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-wrap::before {
    border-top-left-radius: clamp(12px, 5.65vw, 90px);
    border-bottom-left-radius: clamp(12px, 5.65vw, 90px);
  }
}
@media (min-width: 576px) and (max-width: 1023px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-img {
    display: flex;
    flex-direction: row-reverse;
  }
}
@media (min-width: 1024px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-img {
    right: 0;
  }
}
@media (max-width: 575px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-img {
    margin-left: 10%;
  }
}
.pickup02 > *:nth-of-type(odd) .pickup02-img__inner {
  overflow: hidden;
}
@media (min-width: 576px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-img__inner {
    border-top-left-radius: clamp(6px, 3.25vw, 52px);
    border-bottom-left-radius: clamp(6px, 3.25vw, 52px);
  }
}
@media (max-width: 575px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-img__inner {
    border-radius: clamp(6px, 3.25vw, 52px);
  }
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__tit em {
  color: #C19F8A;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__tit em::after {
  background-image: url(../img/common/tit-light_brown.svg);
}
.pickup02 > *:nth-of-type(even) .pickup02-wrap::before {
  top: 0;
  left: 0;
  background: #F3FAFB;
}
@media (min-width: 576px) {
  .pickup02 > *:nth-of-type(even) .pickup02-wrap::before {
    border-top-right-radius: clamp(12px, 5.65vw, 90px);
    border-bottom-right-radius: clamp(12px, 5.65vw, 90px);
  }
}
@media (min-width: 576px) {
  .pickup02 > *:nth-of-type(even) .pickup02-img {
    left: 0;
  }
}
@media (max-width: 575px) {
  .pickup02 > *:nth-of-type(even) .pickup02-img {
    margin-left: 10%;
  }
}
.pickup02 > *:nth-of-type(even) .pickup02-img__inner {
  overflow: hidden;
}
@media (min-width: 576px) {
  .pickup02 > *:nth-of-type(even) .pickup02-img__inner {
    border-top-right-radius: clamp(6px, 3.25vw, 52px);
    border-bottom-right-radius: clamp(6px, 3.25vw, 52px);
  }
}
@media (max-width: 575px) {
  .pickup02 > *:nth-of-type(even) .pickup02-img__inner {
    border-radius: clamp(6px, 3.25vw, 52px);
  }
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__tit em {
  color: #3AA6B9;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__tit em::after {
  background-image: url(../img/common/tit-light_blue.svg);
}
.pickup02 > *:nth-of-type(even) .l-cont {
  display: flex;
  flex-direction: row-reverse;
}

@media (min-width: 1024px) {
  .pickup02-img {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
  }
}
@media (min-width: 1553px) {
  .pickup02-img {
    width: 48%;
  }
}
@media (max-width: 575px) {
  .pickup02-img {
    width: 80%;
  }
}
@media (min-width: 1024px) {
  .pickup02-img__wrap {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1023px) {
  .pickup02-img__wrap {
    width: 85%;
  }
}
@media (max-width: 575px) {
  .pickup02-img__wrap {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .pickup02-img__inner {
    margin: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .pickup02-img__inner div {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1023px) {
  .pickup02-img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .pickup02-img__inner::before {
    content: "";
    display: block;
    padding-top: 56.25%;
    position: relative;
  }
  .pickup02-img__inner > div,
  .pickup02-img__inner figure,
  .pickup02-img__inner a,
  .pickup02-img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .pickup02-img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .pickup02-img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .pickup02-img__inner > div,
  .pickup02-img__inner figure,
  .pickup02-img__inner a,
  .pickup02-img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.pickup02-txt {
  width: 100%;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 2;
  border-radius: clamp(6px, 3.25vw, 52px);
}
@media (min-width: 1024px) {
  .pickup02-txt {
    width: 60%;
  }
}
@media (min-width: 1553px) {
  .pickup02-txt {
    width: 66.66%;
  }
}
@media (max-width: 1023px) {
  .pickup02-txt {
    margin-top: clamp(-3rem, -9vw, -8rem);
  }
}
.pickup02-txt__inner {
  padding: clamp(2em, 3vw, 3rem) 8%;
}
@media (min-width: 768px) {
  .pickup02-txt__inner {
    padding: clamp(3em, 5vw, 5em) 12% clamp(3em, 5vw, 5em);
  }
}
@media (min-width: 1553px) {
  .pickup02-txt__inner {
    padding: clamp(3em, 6vw, 6em) 16% clamp(3em, 6vw, 6em) 12%;
  }
}
.pickup02-txt__tit {
  line-height: 1.2;
  margin-bottom: clamp(1em, 2vw, 2rem);
}
.pickup02-txt__tit em {
  font-family: 'Zen Old Mincho', sans-serif;
  font-weight: 500;
  letter-spacing: 0.02;
  font-style: normal;
  display: inline-block;
  font-size: calc(2rem + 40 * (100vw - 280px) / 1360);
  margin-bottom: 0.2em;
  position: relative;
  padding-right: 1.2em;
}
@media (min-width: 768px) {
  .pickup02-txt__tit em {
    font-size: calc(2rem + 30 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1024px) {
  .pickup02-txt__tit em {
    padding-right: 1.4em;
  }
}
@media (min-width: 1553px) {
  .pickup02-txt__tit em {
    font-size: 3.75rem;
  }
}
.pickup02-txt__tit em::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(24px, 4vw, 58px);
  height: clamp(30px, 4.51vw, 65px);
  background: center center/contain no-repeat;
}
@media (min-width: 1553px) {
  .pickup02-txt__tit em::after {
    width: 58px;
    height: 65px;
  }
}
.pickup02-txt__tit b {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 700;
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  color: #5A5141;
}
@media (min-width: 768px) {
  .pickup02-txt__tit b {
    font-size: calc(1.25rem + 10 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1553px) {
  .pickup02-txt__tit b {
    font-size: 1.88rem;
  }
}
.pickup02-txt__catch {
  margin-bottom: clamp(0.5em, 1vw, 0.75em);
  font-size: calc(1rem + 6 * (100vw - 280px) / 1360);
  line-height: 1.5;
}
@media (min-width: 768px) {
  .pickup02-txt__catch {
    font-size: calc(1rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1553px) {
  .pickup02-txt__catch {
    font-size: 1.25rem;
  }
}
.pickup02-txt__catch strong {
  position: relative;
  color: #202020;
  padding-right: 0.5em;
}

/*--------------------------------------------------------------------/
	top works
/--------------------------------------------------------------------*/
.top-works {
  padding-top: clamp(5rem, 9vw, 9rem);
  padding-bottom: clamp(6rem, 10vw, 12rem);
  position: relative;
  overflow: hidden;
}
.top-works__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 60%;
  max-height: 440px;
  z-index: 1;
  width: 100%;
}
.top-works__bg__inner {
  position: relative;
  max-height: 440px;
  overflow: hidden;
}
.top-works__inner {
  position: relative;
  z-index: 3;
}
.top-works__tit {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.top-works__tit em {
  font-family: 'Zen Old Mincho', sans-serif;
  font-size: calc(2.25rem + 40 * (100vw - 280px) / 1360);
  font-weight: 500;
  padding-right: clamp(3rem, 6vw, 6rem);
  position: relative;
  font-style: normal;
  color: #C19F8A;
  display: inline-block;
}
@media (min-width: 1553px) {
  .top-works__tit em {
    font-size: 5rem;
  }
}
.top-works__tit em::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(24px, 4vw, 58px);
  height: clamp(30px, 4.51vw, 65px);
  background: url(../img/common/tit-light_brown.svg) center center/contain no-repeat;
}
@media (min-width: 1553px) {
  .top-works__tit em::after {
    width: 58px;
    height: 65px;
  }
}
.top-works__tit b {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  color: #5A5141;
  display: block;
  font-weight: 700;
}
@media (min-width: 1553px) {
  .top-works__tit b {
    font-size: 2.25rem;
  }
}
.top-works__list .is-column {
  margin-bottom: clamp(2rem, 5vw, 5rem);
}
.top-works__btn {
  margin-left: auto;
  margin-right: auto;
}
.top-works__btn a {
  font-weight: 500;
  display: block;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-size: 1.07em;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #ffffff;
  background-color: #DA697F;
  border: 1px solid;
  border-color: #DA697F;
  padding: 1.25em 4%;
  width: 100%;
  border-radius: 999px;
}
.top-works__btn a:after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-works__btn a:hover {
  color: #ffffff;
  background-color: #eb234a;
  border-color: #eb234a;
}
.top-works__btn a:hover::after {
  color: #ffffff;
}
.top-works__btn a::after {
  position: absolute;
  right: 1rem;
  top: 50%;
  font-size: 1.5em;
  transform: translateY(-50%);
  color: #ffffff;
}
@media (max-width: 1023px) {
  .top-works__btn a {
    font-weight: 500;
    display: block;
    text-align: center;
    text-decoration: none;
    position: relative;
    line-height: 1.4;
    font-size: 1.07em;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    color: #ffffff;
    background-color: #DA697F;
    border: 1px solid;
    border-color: #DA697F;
    padding: 1.25em 4%;
    width: 100%;
    border-radius: 999px;
  }
  .top-works__btn a:after {
    content: "\e315";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .top-works__btn a:hover {
    color: #ffffff;
    background-color: #eb234a;
    border-color: #eb234a;
  }
  .top-works__btn a:hover::after {
    color: #ffffff;
  }
  .top-works__btn a::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    font-size: 1.5em;
    transform: translateY(-50%);
    color: #ffffff;
  }
}

.top-works {
  position: relative;
}
.top-works .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
  margin-bottom: 5rem;
}
@media (min-width: 1553px) {
  .top-works .swiper {
    margin-bottom: 7rem;
  }
}
.top-works .swiper-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1440 px;
}
@media (min-width: 1921px) {
  .top-works .swiper-control {
    max-width: 75vw;
  }
}
.top-works .swiper-next_prev {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 32px);
  left: -16px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .top-works .swiper-next_prev {
    width: calc(100% + 64px);
    left: -32px;
  }
}
@media (min-width: 1024px) {
  .top-works .swiper-next_prev {
    width: calc(100% + 90px);
    left: -45px;
  }
}
.top-works .swiper-button-prev {
  left: 0;
}
.top-works .swiper-button-next {
  right: 0;
}
.top-works .swiper-button-prev,
.top-works .swiper-button-next {
  margin: 0;
  float: none;
  display: block;
  background-image: none;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
@media (min-width: 768px) {
  .top-works .swiper-button-prev,
  .top-works .swiper-button-next {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1024px) {
  .top-works .swiper-button-prev,
  .top-works .swiper-button-next {
    width: 90px;
    height: 90px;
  }
}
.top-works .swiper-button-prev::before,
.top-works .swiper-button-next::before {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #C19F8A;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.top-works .swiper-button-prev::after,
.top-works .swiper-button-next::after {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #C19F8A;
  font-size: 1.5em;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
@media (min-width: 1024px) {
  .top-works .swiper-button-prev::after,
  .top-works .swiper-button-next::after {
    font-size: 3rem;
  }
}
.top-works .swiper-button-prev:hover::before,
.top-works .swiper-button-next:hover::before {
  background: #DA697F;
  border-color: #DA697F;
}
.top-works .swiper-button-prev:hover::after,
.top-works .swiper-button-next:hover::after {
  color: #ffffff;
}
.top-works .swiper-button-prev:after {
  content: "\e902";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-works .swiper-button-prev::after {
  transform: translate(-60%, -50%);
}
.top-works .swiper-button-next:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-works .swiper-button-next::after {
  transform: translate(-40%, -50%);
}
.top-works .swiper-scrollbar {
  position: absolute;
  top: calc(100% + 2rem);
  width: 100%;
  height: 6px;
  z-index: 1;
  background: #f6f6f6;
}
@media (min-width: 1024px) {
  .top-works .swiper-scrollbar {
    height: 8px;
    top: calc(100% + 3rem);
  }
}
.top-works .swiper-scrollbar-drag {
  background: #C9CED2;
  height: 6px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .top-works .swiper-scrollbar-drag {
    height: 8px;
  }
}
.top-works .swiper-scrollbar-drag:hover {
  background-color: #DA697F;
}

/*--------------------------------------------------------------------/
	top topnews
/--------------------------------------------------------------------*/
.top-topnews {
  padding: clamp(6rem, 12vw, 12rem) 0;
  background: #F2F7FF;
  overflow: hidden;
}
.top-topnews__inner {
  display: flex;
}
@media (min-width: 1024px) {
  .top-topnews__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1023px) {
  .top-topnews__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1024px) {
  .top-topnews__tit {
    width: 30.9%;
  }
}
.top-topnews__tit em {
  font-family: 'Zen Old Mincho', sans-serif;
  color: #000000;
  font-size: calc(3rem + 70 * (100vw - 280px) / 1360);
  font-weight: 500;
  padding-right: clamp(3rem, 6vw, 6rem);
  position: relative;
  font-style: normal;
  display: block;
}
@media (min-width: 1553px) {
  .top-topnews__tit em {
    font-size: 7.5rem;
  }
}
.top-topnews__tit b {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  color: #DA697F;
  display: block;
  font-weight: 700;
}
@media (min-width: 1553px) {
  .top-topnews__tit b {
    font-size: 2.25rem;
  }
}
.top-topnews__table {
  max-height: 520px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #C9CED2 #ffffff;
}
.top-topnews__table::-webkit-scrollbar {
  width: 8px;
}
.top-topnews__table::-webkit-scrollbar-track {
  background: #f6f6f6;
}
.top-topnews__table::-webkit-scrollbar-thumb {
  background: #C9CED2;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #ffffff;
}
@media (min-width: 1024px) {
  .top-topnews__table {
    width: 65.55%;
    margin-top: clamp(2rem, 3vw, 3rem);
  }
}
.top-topnews__table__inner tr {
  display: block;
  border-bottom: 1px solid #C9CED2;
}
.top-topnews__table__inner tr:first-child {
  border-top: 1px solid #C9CED2;
}
.top-topnews__table__inner th {
  padding: 1.25em 0;
  min-width: 100px;
  text-align: left;
  letter-spacing: 0;
  color: #DA697F;
  font-family: 'Zen Old Mincho', sans-serif;
  font-weight: 500;
}
@media (min-width: 576px) {
  .top-topnews__table__inner th {
    width: 16%;
  }
}
@media (min-width: 1553px) {
  .top-topnews__table__inner th {
    font-size: 1.19rem;
  }
}
@media (max-width: 575px) {
  .top-topnews__table__inner th {
    width: 100%;
    display: block;
    padding: 1.25em 0 0 0;
    font-size: 1rem;
  }
}
.top-topnews__table__inner td {
  padding: 1.25em 0;
}
@media (min-width: 576px) {
  .top-topnews__table__inner td {
    width: 84%;
  }
}
@media (max-width: 575px) {
  .top-topnews__table__inner td {
    width: 100%;
    display: block;
    padding: 0 0 1.25em 0;
  }
}

/*--------------------------------------------------------------------/
	top feed
/--------------------------------------------------------------------*/
.top-feed {
  padding: 0 0 clamp(6em, 12vw, 12rem);
}
.top-feed__flex {
  display: flex;
}
@media (min-width: 768px) {
  .top-feed__flex {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .top-feed__flex {
    flex-direction: column;
    row-gap: clamp(3em, 6vw, 6em);
  }
}

.top-feed__box {
  padding-bottom: clamp(6em, 7vw, 8em);
  position: relative;
}
@media (min-width: 768px) {
  .top-feed__box {
    padding-bottom: 8em;
    width: 48%;
  }
}
.top-feed__box__tit {
  margin-bottom: clamp(1em, 1.75vw, 1.5em);
  position: relative;
}
.top-feed__box__tit b {
  font-family: 'Zen Old Mincho', sans-serif;
  color: #C19F8A;
  font-size: calc(1.75rem + 36 * (100vw - 280px) / 1360);
  font-weight: 400;
  position: relative;
  display: inline-block;
  line-height: 1.2;
  letter-spacing: 0.05em;
  padding-top: 0.3em;
  padding-right: 1.2em;
  margin-bottom: 0.2em;
}
@media (min-width: 768px) {
  .top-feed__box__tit b {
    font-size: calc(2rem + 30 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1024px) {
  .top-feed__box__tit b {
    padding-right: 1.4em;
  }
}
@media (min-width: 1553px) {
  .top-feed__box__tit b {
    font-size: 3.75rem;
  }
}
.top-feed__box__tit b::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: clamp(24px, 4vw, 58px);
  height: clamp(30px, 4.51vw, 65px);
  background: url(../img/common/tit-light_brown.svg) center center/contain no-repeat;
}
@media (min-width: 1553px) {
  .top-feed__box__tit b::after {
    width: 58px;
    height: 65px;
  }
}
.top-feed__box__tit small {
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1360);
  color: #5A5141;
  display: block;
}
@media (min-width: 768px) {
  .top-feed__box__tit small {
    font-size: calc(1.14rem + 8 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1553px) {
  .top-feed__box__tit small {
    font-size: 1.75rem;
  }
}
.top-feed__box__list {
  width: 100%;
  overflow-y: auto;
  max-height: 400px;
  border-top: 1px solid #E5E9EC;
  padding-right: 1em;
}
@media (min-width: 1553px) {
  .top-feed__box__list {
    max-height: 560px;
  }
}
.top-feed__box .btn-wrap {
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 1024px) {
  .top-feed__box .btn-wrap {
    left: 20%;
    width: 60%;
  }
}

.top-feed__item a {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.75em 0 1em;
  text-decoration: none !important;
  color: #202020;
  border-bottom: 1px solid #E5E9EC;
}
.top-feed__item a::before {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  width: 0;
  height: 1px;
  background: #DA697F;
  transition: all 0.3s ease 0s;
  z-index: 1;
}
.top-feed__item a:hover::before {
  width: 100%;
}
.top-feed__item__tit {
  order: 3;
  font-weight: 500;
}
.top-feed__item__date {
  order: 1;
  font-size: 0.94em;
  line-height: 1.4;
  margin-bottom: 0.2em;
}
.top-feed__item__category {
  order: 2;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.4em;
  gap: 0.5em 0.4em;
}
.top-feed__item__category .category-item {
  font-size: 0.75em;
}

.top-commonpost1 {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .top-commonpost1 {
    column-gap: 6%;
  }
}
@media (min-width: 1553px) {
  .top-commonpost1 {
    column-gap: 10%;
  }
}
@media (max-width: 767px) {
  .top-commonpost1 {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (max-width: 575px) {
  .top-commonpost1 {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .top-commonpost1::after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 46.66%;
    background: #E5E9EC;
  }
}
@media (min-width: 1553px) {
  .top-commonpost1::after {
    left: 46.66%;
  }
}

@media (min-width: 768px) {
  .top-commonpost1__newest {
    width: 43.66%;
  }
}
@media (min-width: 1553px) {
  .top-commonpost1__newest {
    width: 41.66%;
  }
}

.top-commonpost1__newest__box a {
  display: block;
  text-decoration: none !important;
  color: #202020;
  transition: all 0.3s ease 0s;
}
.top-commonpost1__newest__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-commonpost1__newest__box__inner {
  display: flex;
  overflow: hidden;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__inner {
    flex-direction: column-reverse;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-commonpost1__newest__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__newest__box__inner {
    flex-direction: column-reverse;
  }
}
.top-commonpost1__newest__box__img {
  width: 100%;
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-commonpost1__newest__box__img {
    width: 35.91%;
  }
}
.top-commonpost1__newest__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.top-commonpost1__newest__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.top-commonpost1__newest__box__img__inner > div,
.top-commonpost1__newest__box__img__inner figure,
.top-commonpost1__newest__box__img__inner a,
.top-commonpost1__newest__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__txt {
    padding: 0.5em 1.5em 1.5em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-commonpost1__newest__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__newest__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-commonpost1__newest__box__date {
  font-family: 'Zen Old Mincho', sans-serif;
  letter-spacing: 0;
  font-weight: 500;
  color: #DA697F;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__date {
    font-size: 1.15em;
  }
}
.top-commonpost1__newest__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__category {
    margin-bottom: calc(0.5rem + 0.3em);
  }
}
.top-commonpost1__newest__box__category .category-item {
  font-size: 0.7em;
}
@media (min-width: 1553px) {
  .top-commonpost1__newest__box__category .category-item {
    font-size: 0.8em;
  }
}
.top-commonpost1__newest__box__tit {
  font-weight: 700;
  font-size: calc(0.94rem + 6 * (100vw - 280px) / 1360);
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__tit {
    font-size: calc(0.9rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1553px) {
  .top-commonpost1__newest__box__tit {
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box .txt {
    font-size: 0.9em;
  }
}
@media (min-width: 1553px) {
  .top-commonpost1__newest__box .txt {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .top-commonpost1__newest__box .txt {
    display: none;
  }
}

.top-commonpost1__list {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .top-commonpost1__list {
    row-gap: 1em;
    width: 50.34%;
    justify-content: space-between;
  }
}
@media (min-width: 1553px) {
  .top-commonpost1__list {
    width: 48.34%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}

.top-commonpost1__list__box {
  position: relative;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .top-commonpost1__list__box {
    height: 100%;
  }
}
.top-commonpost1__list__box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  color: #202020;
  overflow: hidden;
  transition: all 0.3s ease 0s;
}
.top-commonpost1__list__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-commonpost1__list__box__inner {
  display: flex;
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list__box__inner {
    flex-direction: column-reverse;
  }
}
.top-commonpost1__list__box__img {
  width: 100%;
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__img {
    width: 35.91%;
  }
}
.top-commonpost1__list__box__img__inner {
  margin: 0;
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__img__inner {
    height: 100%;
  }
  .top-commonpost1__list__box__img__inner > div {
    height: 100%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list__box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-commonpost1__list__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .top-commonpost1__list__box__img__inner > div,
  .top-commonpost1__list__box__img__inner figure,
  .top-commonpost1__list__box__img__inner a,
  .top-commonpost1__list__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-commonpost1__list__box__date {
  font-family: 'Zen Old Mincho', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  color: #DA697F;
}
.top-commonpost1__list__box__tit {
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .top-commonpost1__list__box__tit {
    font-size: calc(0.9rem + 2 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1553px) {
  .top-commonpost1__list__box__tit {
    font-size: 1rem;
  }
}
.top-commonpost1__list__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
  row-gap: 0.2em;
}
.top-commonpost1__list__box__category .category-item {
  font-size: 0.7em;
}

.top-commonpost2__list {
  display: flex;
}
@media (min-width: 768px) {
  .top-commonpost2__list {
    flex-wrap: wrap;
    gap: 1.5em 2%;
  }
}
@media (min-width: 1553px) {
  .top-commonpost2__list {
    gap: 2em 3%;
  }
}
@media (max-width: 767px) {
  .top-commonpost2__list {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (max-width: 575px) {
  .top-commonpost2__list {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .top-commonpost2__list > * {
    width: 49%;
  }
}
@media (min-width: 1553px) {
  .top-commonpost2__list > * {
    width: 48.5%;
  }
}
@media (max-width: 767px) {
  .top-commonpost2__list > * {
    width: 100%;
  }
}

.top-commonpost2__box {
  position: relative;
  background: #ffffff;
  text-align: left;
}
.top-commonpost2__box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  transition: all 0.3s ease 0s;
  color: #202020;
  overflow: hidden;
}
.top-commonpost2__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-commonpost2__box__inner {
  display: flex;
}
@media (min-width: 576px) {
  .top-commonpost2__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-commonpost2__box__inner {
    flex-direction: column-reverse;
  }
}
.top-commonpost2__box__img {
  width: 100%;
}
@media (min-width: 576px) {
  .top-commonpost2__box__img {
    width: 35.91%;
  }
}
@media (min-width: 768px) {
  .top-commonpost2__box__img {
    width: 25%;
  }
}
@media (min-width: 1553px) {
  .top-commonpost2__box__img {
    width: 240px;
  }
}
.top-commonpost2__box__img__inner {
  margin: 0;
}
@media (min-width: 576px) {
  .top-commonpost2__box__img__inner {
    height: 100%;
  }
  .top-commonpost2__box__img__inner > div {
    height: 100%;
  }
}
@media (max-width: 575px) {
  .top-commonpost2__box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-commonpost2__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .top-commonpost2__box__img__inner > div,
  .top-commonpost2__box__img__inner figure,
  .top-commonpost2__box__img__inner a,
  .top-commonpost2__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .top-commonpost2__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (min-width: 768px) {
  .top-commonpost2__box__txt {
    width: 75%;
  }
}
@media (min-width: 1553px) {
  .top-commonpost2__box__txt {
    width: calc(100% - 240px);
  }
}
@media (max-width: 575px) {
  .top-commonpost2__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-commonpost2__box__txt__inner {
  width: 100%;
}
.top-commonpost2__box__date {
  font-family: 'Zen Old Mincho', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  color: #DA697F;
}
.top-commonpost2__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
  row-gap: 0.2em;
}
.top-commonpost2__box__category .category-item {
  font-size: 0.7em;
}
.top-commonpost2__box__tit {
  font-size: calc(1rem + 3 * (100vw - 280px) / 1360);
  margin-bottom: 0.5em;
}
@media (min-width: 576px) {
  .top-commonpost2__box__tit {
    font-size: calc(1rem + 4 * (100vw - 576px) / 1064);
  }
}
@media (min-width: 768px) {
  .top-commonpost2__box__tit {
    font-size: calc(0.94rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1553px) {
  .top-commonpost2__box__tit {
    font-size: 1.31rem;
  }
}